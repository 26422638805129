<template>
  <v-card outlined>
    <v-card-title>Datos del curso</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-select
            v-model="formData.status"
            :items="courseStatusOptions"
            :loading="loadingCourseStatus"
            label="Estado"
            item-text="name"
            item-value="id"
            :rules="[$data.$globalRules.required]"
            :disabled="formData.status === 'closed'"
            dense
            outlined
          />
        </v-col>

        <v-col v-if="courseStatus === 'active'" cols="12">
          <v-text-field
            v-model="formData.code"
            label="Código de curso"
            :rules="[this.$data.$globalRules.required]"
            dense
            outlined
          />
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="formData.organizationCompanyId"
            :items="organizationCompanies"
            :loading="loadingOrganizationCompanies"
            label="Entidad organizativa"
            item-text="name"
            item-value="id"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-prd-date-picker-field
            dense
            v-model="formData.startDate"
            :rules="[$data.$globalRules.required]"
            label="Fecha del curso"
            required
          />
        </v-col>

        <v-col cols="12">
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="startTime.hour"
                prepend-icon="mdi-clock-time-four-outline"
                label="Hora"
                type="number"
                validate-on-blur
                :rules="[
                  $data.$globalRules.required,
                  $data.$globalRules.number,
                  $data.$globalRules.hour,
                ]"
                dense
                @blur="setStartTime"
              />
            </v-col>

            <v-col>
              <v-text-field
                v-model="startTime.minute"
                label="Minuto"
                type="number"
                validate-on-blur
                :rules="[
                  $data.$globalRules.required,
                  $data.$globalRules.number,
                  $data.$globalRules.minute,
                ]"
                dense
                @blur="setStartTime"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="formData.categoryId"
            :items="categoriesOptions"
            :loading="loadingCategories"
            label="Categoría"
            item-text="title"
            item-value="const"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="formData.directorId"
            :items="directorsOptions"
            :loading="loadingCategories"
            label="Director de formación"
            item-text="name"
            item-value="id"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="formData.modality"
            :items="modalitiesOptions"
            :loading="loadingModalitiesOptions"
            label="Modalidad"
            item-text="name"
            item-value="id"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="formData.duration"
            type="number"
            :rules="[$data.$globalRules.required, $data.$globalRules.number]"
            label="Horas de duración"
            dense
          />
        </v-col>

        <v-col cols="12" class="subtitle-2 mb-2"> Datos estimados </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="formData.estimatedSessionsQuantity"
            label="Sesiones"
            type="number"
            :rules="[$data.$globalRules.required, $data.$globalRules.number]"
            dense
          />
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="formData.estimatedInscriptionsQuantity"
            label="Participantes"
            type="number"
            :rules="[$data.$globalRules.required, $data.$globalRules.number]"
            dense
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    vPrdDatePickerField: () => import("@/components/v-prd/DatePickerTextField"),
  },
  props: {
    formData: { type: Object, required: true },
    courseStatus: { type: String, default: "" },
  },
  data: () => ({
    startTime: {
      hour: null,
      minute: null,
    },
    modalitiesOptions: [],
    loadingModalitiesOptions: null,
    categoriesOptions: [],
    loadingCategories: null,
    directorsOptions: [],
    loadingDirectors: null,
    courseStatusOptions: [],
    loadingCourseStatus: null,
    organizationCompanies: [],
    loadingOrganizationCompanies: null,
    currentService: null,
  }),
  beforeMount() {
    const startTimeArr = this.formData.startTime?.split(":");
    if (startTimeArr) {
      [this.startTime.hour, this.startTime.minute] = startTimeArr;
    }
  },
  async mounted() {
    this.currentService = this.$store.state.currentService;
    await this.getCourseModalities();
    await this.getCourseStatusOptions();
    await this.getCourseCategories();
    await this.getOrganizationCompanies();
    await this.getDirectors();
  },
  methods: {
    async getCourseModalities() {
      try {
        this.loadingModalitiesOptions = true;
        const response = await this.currentService.getCourseModalities();
        this.modalitiesOptions = response.data;
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar las modalidades de curso | " + e);
      } finally {
        this.loadingModalitiesOptions = false;
      }
    },
    async getCourseStatusOptions() {
      try {
        this.loadingCourseStatus = true;
        const response = await this.currentService.getCourseStatus();
        this.courseStatusOptions = response.data;
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar lo estados e curso | " + e);
      } finally {
        this.loadingCourseStatus = false;
      }
    },
    async getCourseCategories() {
      try {
        this.loadingCategories = true;
        const response = await this.currentService.getTrainingCourseCategories();
        this.categoriesOptions = response.data;
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar las categorías de cursos | " + e);
      } finally {
        this.loadingCategories = false;
      }
    },
    async getDirectors() {
      try {
          this.loadingDirectors = true;
          const response = await this.currentService.getDirectors(this.$route.params.id);
          this.directorsOptions = response.data;
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar el listado de directores | " + e);
      } finally {
          this.loadingDirectors = false;
      }
    },
    async getOrganizationCompanies() {
      try {
        this.loadingOrganizationCompanies = true;
        const response = await this.currentService.getOrganizationCompanies();
        this.organizationCompanies = response.data;
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar las entidades organizativas | " + e);
      } finally {
        this.loadingOrganizationCompanies = false;
      }
    },
    setStartTime() {
      if (this.startTime.hour.length === 1) {
        this.startTime.hour = 0 + this.startTime.hour;
      }
      if (this.startTime.minute.length === 1) {
        this.startTime.minute = 0 + this.startTime.minute;
      }
      this.formData.startTime = this.startTime.hour + ":" + this.startTime.minute;
    },
  },
  computed: {
    courseCodeRules() {
      const statusWithoutCourseCode = ["pending", "accepted"];
      const isNotRequired = statusWithoutCourseCode.some(s => this.courseStatus === s);
      return isNotRequired ? [] : [this.$data.$globalRules.required];
    },
  },
};
</script>

<style scoped></style>
